<template>
  <div>
    <v-content>
      <v-container>
        <v-card flat class="df-b-c">
          <v-card-title>
            <v-spacer></v-spacer>
            <img style="width: 40%" src="../../assets/congratulate.png"/>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title style="font-size: 24px" :style="`color: ${$vuetify.theme.themes.light.primary}`">
            <v-spacer></v-spacer>
            ALL SET {{personal.firstName}}!
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text align="center" class="bold-f-s">Thank you for doing business with <br/> {{portfolio.displayName}}</v-card-text>
          <v-card-text>
            Your application is now undergoing a final review. Once approved you will receive an email confirmation
            and will be funded <span class="bold-f">{{ new Date(parseInt(loanDetail.effectiveDate)).format("yyyy-MM-dd") }}</span>. <br/><br/>
            If you have any questions you can reach us
            at <span class="bold-f">{{tool.formatPhone(portfolio.telephone)}}</span> or email us at <span class="bold-f">{{portfolio.supportEmail}}</span>.
          </v-card-text>
        </v-card>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import { DataType, Tool } from '../../js/core'
import { OriginationApi } from '../../api/application'

export default {
  data () {
    return {
      page: DataType.PAGES.SUCCESS,
      personal: {},
      firstName: '',
      cellPhone: '',
      portfolioPhoneNumber: '',
      portfolio: {},
      loanDetail: {},
      tool: new Tool()
    }
  },
  created () {
    this.$store.commit('setCurrentPage', this.page)
    this.personal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    this.firstName = this.personal.firstName
    this.cellPhone = this.personal.cellPhone
    this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    this.portfolio.portfolioPhoneNumber = this.portfolio.telephone
    this.portfolio.portfolioEmail = this.portfolio.email
    const _this = this
    OriginationApi.getLoanById({
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      feature: 'PAYMENT_OPTION'
    }, function (result) {
      _this.loanDetail = result
    }, function (res) {
      _this.$router.push(_this.route)
    }, function () {
      _this.error()
    })
  },
  methods: {
    toPhone (mun) {
      if (!mun) return ''
      mun = mun.replace(/\D/g, '')
      if (mun.length > 6) {
        const a = mun.slice(0, 3)
        const b = mun.slice(3, 6)
        const c = mun.slice(6, mun.length)
        mun = '(' + a + ') ' + b + '-' + c
      }
      return mun
    }
  }
}
</script>
